* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.App {
  padding-top: 3rem;
  text-align: center;
  height: 100vh;
  background: #333333;
}
.password {
  background-color: transparent;
  border: none;
}
#password-text {
  color: white;
  cursor: pointer;
}
.checkboxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30vw;
}
@media screen and (max-width: 1300px) {
  .checkboxes {
    flex-direction: column;
  }
}
.checkbox-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.slider {
  width: 25vw;
}
